import {getQuarter} from 'date-fns';
import * as React from 'react';
import {Col, Row} from 'react-bootstrap';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {setLoading, setSuccess} from '../../../actions/loadingActions';
import {quarters, years} from '../../../constants/generals';
import {IFilters, IHistory, IQuarterlyForms} from '../../../interfaces';
import {QuarterlyFormModel} from '../../../model/QuarterlyFormModel';
import {getQuarterlyFormList} from '../../../services/quarterlyFormService';
import {BasePage} from '../../reusableComponents/BasePage';
import {BarChart} from '../../reusableComponents/ChartComponents/BarChart';
import {PieChart} from '../../reusableComponents/ChartComponents/PieChart';
import {ListPage} from '../../reusableComponents/ListPage';
import {RegionFilter} from '../../reusableComponents/RegionFilter';
import {SelectDropdown} from '../../reusableComponents/SelectDropdown';
import {ListItem} from '../InspectionReportDetailer/ListItem';
import { RegionModel } from '../../../model/OrgModel';
import { getRegionsByOrg } from '../../../services/orgService';
import { getUserData } from '../../../services/loginService';
import { getCurentDate } from '../../../utils/generalUtils';
import moment from 'moment';
import { Pagination } from '../../reusableComponents/Pagination';

const queryString = require('query-string');

export interface IQuarterlyReportsProps {
    quarterlyForms?: IQuarterlyForms;
    filters?: IFilters;
    history?: IHistory;
    location?: any;
    instances: any;
    axiosPreviousToken: any;
    regionList: [];
}

export interface IQuarterlyReportsState {
    data: Array<any>;
    filterBy: string;
    year: string;
    quarter: string;
    region: string;
    isAsc: boolean;
    sortIndex: any;
    curPageNo: any;
    searchText: any;
    totCount: any;
}

export class QuarterlyReportsImpl extends React.Component<IQuarterlyReportsProps, IQuarterlyReportsState> {
    loggedInUserInfo = getUserData();
    constructor(props: IQuarterlyReportsProps) {
        super(props);
        //let year = new Date().getFullYear().toString();
        let date = moment();
        let year = getCurentDate(date, 'yyyy');
        let region = 'All';
        //let quarter = getQuarter(new Date()).toString();
        let quarter = getQuarter(getCurentDate(date, 'MM/DD/yyyy', true)).toString();
        if (this.props.location.search) {
            year = queryString.parse(this.props.location.search).year;
            quarter = queryString.parse(this.props.location.search).quarter;
            region = queryString.parse(this.props.location.search).region;
        }
        this.state = {
            data: [],
            region,
            filterBy: '',
            year, quarter,
            isAsc: false,
            sortIndex: 'submittedDate',
            curPageNo: 1,
            searchText:'',
            totCount:0  
        };
    }

    promise = async () => {
         //let year = new Date().getFullYear().toString();
        let date = moment();
        let year = getCurentDate(date, 'yyyy');
        let region = 'All';
        let search = '';
        //let quarter = getQuarter(new Date()).toString();
        let quarter = getQuarter(getCurentDate(date, 'MM/DD/yyyy', true)).toString();
        if (this.props.location.search) {
            year = queryString.parse(this.props.location.search).year;
            quarter = queryString.parse(this.props.location.search).quarter;
            region = queryString.parse(this.props.location.search).region;
            search = queryString.parse(this.props.location.search).search;
        }
        await getQuarterlyFormList(quarter, year, 'All', region, false,'submittedDate',1,search,null).then(res => {
            this.setState({
                data: res?.data && res.data.data && res.data.data.length ? res.data.data : [],
                totCount: res?.data.TotalCount
            });
        });
        if(RegionModel.list().length===0) {
            await getRegionsByOrg(this.loggedInUserInfo.orgId)
        }
    }

    renderLineChart = () => {
        const {quarterlyForms} = this.props;
        if (!quarterlyForms) {
            return <div/>;
        }

        const quarterlyFormsData: Array<any> = [];
        quarterlyFormsData.push({
            name: 'Quarter Visual', 'Complete Forms': quarterlyForms.QTRVisual.Complete,
            'Incomplete Forms': quarterlyForms.QTRVisual.InComplete
        });
        quarterlyFormsData.push({
            name: 'ShopInspection', 'Complete Forms': quarterlyForms.ShopInspection.Complete,
            'Incomplete Forms': quarterlyForms.ShopInspection.InComplete
        });
        return <Row className="show-grid line-chart-container" style={{display: 'flex', justifyContent: 'center'}}>
            <Col sm={6} xs={12}>
                <BarChart
                    barColors={['#63AB66', '#DBDBDB']}
                    dataKey={['Complete Forms', 'Incomplete Forms']}
                    data={quarterlyFormsData}
                />
            </Col>
        </Row>;
    }

    renderPieChart = () => {
        const {quarterlyForms} = this.props;
        if (!quarterlyForms) {
            return <div/>;
        }
        const {filterBy} = this.state;
        const dailyFormSummary =
            [{name: 'Complete Forms', value: quarterlyForms[filterBy].Complete},
                {name: 'Incomplete Forms', value: quarterlyForms[filterBy].InComplete}];

        return <Row className="show-grid">
            <Col sm={12} xs={12} className="pie-chart-container">
                <PieChart className="pie-chart-responsive" data={dailyFormSummary} dataKey="value" legends>
                </PieChart>
            </Col>
        </Row>;
    }

    handleRegionFilter = async (a, value) => {
        setLoading('QuarterlyFormList');
        const {quarter, year} = this.state;
        this.setState({region: value});
        const queryString = `?year=${year}&&quarter=${quarter}&&region=${value}`;
        await getQuarterlyFormList(quarter.toString(), year, '', value, this.state.isAsc, this.state.sortIndex, this.state.curPageNo, this.state.searchText, this.props.axiosPreviousToken).then(res => {
            this.setState({
                data: res?.data && res.data.data && res.data.data.length ? res.data.data : [],
                totCount: res?.data.TotalCount
            });
        });
        this.props.history?.push(`/reports/quarterly${queryString}`);
        setSuccess('QuarterlyFormList');
    }

    handleQuarterFilter = async (value) => {
        setLoading('QuarterlyFormList');
        const {year, region} = this.state;
        this.setState({quarter: value});
        const queryString = `?year=${year}&&quarter=${value}&&region=${region}`;
        await getQuarterlyFormList(value, year, '', this.state.region, this.state.isAsc, this.state.sortIndex, this.state.curPageNo, this.state.searchText, this.props.axiosPreviousToken).then(res => {
            this.setState({
                data: res?.data && res.data.data && res.data.data.length ? res.data.data : [],
                totCount: res?.data.TotalCount
            });
        });
        this.props.history?.push(`/reports/quarterly${queryString}`);
        setSuccess('QuarterlyFormList');
    }

    handleYearFilter = async (value) => {
        setLoading('QuarterlyFormList');
        const {quarter, region} = this.state;
        await this.setState({
            year: value,
            isAsc: false,
            sortIndex: 'submittedDate',
            curPageNo: 1
        });
        const queryString = `?year=${value}&&quarter=${quarter}&&region=${region}`;
        await getQuarterlyFormList(quarter.toString(), value, '', this.state.region, this.state.isAsc, this.state.sortIndex, this.state.curPageNo, this.state.searchText, this.props.axiosPreviousToken).then(res => {
            this.setState({
                data: res?.data && res.data.data && res.data.data.length ? res.data.data : [],
                totCount: res?.data.TotalCount
            });
        });
        this.props.history?.push(`/reports/quarterly${queryString}`);
        setSuccess('QuarterlyFormList');
    }

    handleSort = async (Asc, index, searchText) => {
        await this.setState({
            isAsc: Asc,
            sortIndex: index,
            curPageNo: searchText.length > 0 && this.state.searchText == searchText ? this.state.curPageNo : 1,
            searchText: searchText,
        })

        setLoading('QuarterlyFormList');
        const {quarter, year, region} = this.state;
        // this.setState({quarter: value});
        const queryString = `?year=${year}&&quarter=${quarter}&&region=${region}&&search=${this.state.searchText}`;
        await getQuarterlyFormList(quarter, year, '', region, this.state.isAsc, this.state.sortIndex, this.state.curPageNo, this.state.searchText, this.props.axiosPreviousToken).then(res => {
            this.setState({
                data: res?.data && res.data.data && res.data.data.length ? res.data.data : [],
                totCount: res?.data.TotalCount
            });
        });
        this.props.history?.push(`/reports/quarterly${queryString}`);
        setSuccess('QuarterlyFormList');
        
        }
    handlePagination = async () => {

        let curPageNo = this.state.curPageNo || '1';
        setLoading('QuarterlyFormList');
        const {quarter, year, region} = this.state;
        // this.setState({quarter: value});
        // const queryString = `?year=${year}&&quarter=${quarter}&&region=${region}`;
        await getQuarterlyFormList(quarter, year, '', region, this.state.isAsc, this.state.sortIndex, this.state.curPageNo, this.state.searchText, this.props.axiosPreviousToken).then(res => {
            this.setState({
                data: res?.data && res.data.data && res.data.data.length ? res.data.data : []
            });
        });
        // this.props.history?.push(`/reports/quarterly${queryString}`);
        setSuccess('QuarterlyFormList');

    }

    render() {
        const {filterBy,totCount} = this.state;
        let pageno = Math.ceil(totCount/20 );

        return (
            <BasePage className="list-page-base quarterly-reports" pageTitle="Quarterly Reports">
                <ListPage
                    // @ts-ignore
                    identifier="QuarterlyFormList"
                    instances={this.state.data}
                    frequency={'Quarterly'}
                    listItemComponent={ListItem}
                    searchBy={['plantId', 'plantName', 'plantRegionName', 'permitType', 'inspectionName']}
                    instanceOf={'plantInfo'}
                    pageHeadings={['#', 'PlantId', 'Region', 'Facility', 'Permit', 'Form', 'Submitted', 'Last Updated']}
                    listItemFields={['', 'plantId', 'plantRegionName', 'facilityType', 'permitType', 'inspectionName',  'submittedDate', 'lastUpdatedDate']}
                    cols={[1, 1, 2, 1, 1, 2, 2, 2]}
                    promise={this.promise}
                    onHandleSort={this.handleSort}
                    searchPlaceHolder="Enter plant id, plant name, region, permit type, inspection to search..."
                    emptyListMessage="No Quarterly Reports found"
                    chartComponents={filterBy && filterBy !== 'all' ? this.renderPieChart() : this.renderLineChart()}
                    chartTitle=""
                    filterBy={filterBy}
                    chartFilter={<div className="filter-container row"> 
                        <Col xs={6} sm={4} md={4} lg={4} xl={4} >
                            <SelectDropdown
                                placeholder="Quarter"
                                defaultValue={this.state.quarter}
                                onChange={this.handleQuarterFilter}
                                menuItems={quarters()}
                            />
                        </Col>
                        <Col xs={6} sm={4} md={4} lg={4} xl={4} >
                            <SelectDropdown
                                placeholder="Year"
                                onChange={this.handleYearFilter}
                                defaultValue={this.state.year}
                                menuItems={years()}
                            />
                        </Col>
                        <Col xs={12} sm={4} md={4} lg={4} xl={4} >
                            <RegionFilter
                                selectedValue={this.state.region}
                                onChange={this.handleRegionFilter}
                                regionList={this.props.regionList}
                            />
                        </Col>
                    </div>}
                />
                <Pagination
                    promise={async (e: any) => {
                        await this.setState({ curPageNo: e});
                        this.handlePagination(); // Call the function inside the async arrow function
                    }}
                    totalCount={pageno}
                    // Model={OIModel as any} 
                    pageNum={this.state.curPageNo}
                    identifier="AllListPage"
                />
            </BasePage>
        );
    }
}

export function mapStateToProps(state) {
    const instances = QuarterlyFormModel.list(state);
    const axiosPreviousToken = state.miscellaneous.get('axiosPreviousToken');
    const regionList = RegionModel.list();
    return {
        instances, regionList, axiosPreviousToken
    };
}

export const QuarterlyReports =
    withRouter(connect< IQuarterlyReportsProps, any, any>(mapStateToProps)(QuarterlyReportsImpl as any));
