import './homepage.scss';

import * as React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {toast, ToastContainer} from 'react-toastify';

import {IHistory} from '../../interfaces';
import {NavbarComp} from '../reusableComponents/Navbar';
import userIcon from '../../images/userIcon.png';
import dashboardIcon from '../../images/dashboardIcon.png';
import fillFormIcon from '../../images/fillFormIcon.png';
import formSummaryIcon from '../../images/formSummaryIcon.png';
import factoryIcon from '../../images/factoryIcon.png';
import { getUserLoginInfo } from '../../utils/generalUtils';
import { ELMSURL } from '../../constants/url';
import moment from 'moment';

export interface IHomePageProps {
    history?: IHistory;
    userInfo: any;
}

export class HomePageImpl extends React.PureComponent<IHomePageProps, {}> {

    componentDidMount() {
        document.title = 'Welcome to EnviroConnect' as any;
    }

    renderContent = () => {
        const {Access_Level} = this.props.userInfo;
        return <div className="footer">
            <div title="Click to fill out a form." onClick={() => this.props.history?.push('/inspections')}
                className="plants-container footer-data" >
                <div>
                    <img alt={'fill-form-icon'} src={fillFormIcon}/>
                </div>
                <div className="label-container">
                    <span className="footer-brand"> Env'l Inspections</span> 
                </div>
            </div>
            <span className={Access_Level == 'L1' ? "separatorL1" : "separator"} />
            { this.props.userInfo.orgId != "705bf30b-082e-453b-8f51-0ada593e4ca2"?<><div title="Click to fill out a form." onClick={() => this.props.history?.push('/housekeeping')}
                className="plants-container footer-data" >
                <div>
                    <img alt={'fill-form-icon'} src={fillFormIcon}/>
                </div>
                <div className="label-container">
                    <span className="footer-brand">  HouseKeeping</span> 
                </div>
            </div>
            <span className={Access_Level == 'L1' ? "separatorL1" : "separator"} /></>: ''}
            
            <div title="Click to view form summary" className="plants-container footer-data"
                onClick={() => this.props.history?.push('/summary-reports')} >
                <div>
                    <img alt={'form-summary-icon'} src={formSummaryIcon}/>
                </div>
                <div className="label-container">
                    <span className="footer-brand">  Form Summary</span> 
                </div>
            </div>
            <span className="separator"/>
            {this.props.userInfo.Access_Level !== 'L1' ? 
            <>
            <div title="Click to go to Argos Dashboard" className="user-container footer-data"
                onClick={() => this.props.history?.push('/dashboard')} >
                <div>
                    <img alt={'dashboard-icon'} src={dashboardIcon}/>
                </div>
                <div className="label-container">
                    <span className="footer-brand">  Dashboard</span> 
                </div>
            </div>
            
            <span className={Access_Level == 'L1' ? "separatorL1" : "separator"}/></>
            :''}
            <div title="Click to manage plants" className="plants-container footer-data"
                onClick={() => this.props.history?.push('/maintenance')} >
                <div>
                    <img alt={'factory-icon'} src={factoryIcon}/>
                </div>
                <div className="label-container">
                    <span className="footer-brand"> Maintenance</span> 
                </div>
            </div>

        </div>;
    }
    
    processTrainingButton = () => { 
        const { userInfo } = this.props;  
        if(!userInfo.elmsFlag) {  
            /* toast.warning('You are not authorized to access eLMS portal. Please contact your adminstrator', {
                position: toast.POSITION.TOP_RIGHT,
                className: 'failed-toast'
            }); */ 
            window.open(`${ELMSURL}`, '_blank'); 
        } else {
            // window.open(`${ELMSURL}/login/${userInfo.elmsToken}`, '_blank'); 
            window.open(`${'http://localhost:3001/#'}/login/${userInfo.elmsToken}`, '_blank'); 
        
        }
        return false;
    }

    renderTrainingButton = () => {
        const { userInfo } = this.props;  
        let isAccesstoArchive = userInfo.Regions.find(item => item.regionId === "f6d33c3d-a739-11eb-8435-068f2fe83536") || (userInfo.Access_Level==='L1'||userInfo.Access_Level==='L2')?true:false;
    
        return userInfo && <span className='redirect-span'><span className="elms-tab"> <img onClick={() => this.processTrainingButton() } className="elms-logo" src="https://training-portal.s3.us-west-2.amazonaws.com/E-connect_LMS.png" alt="eLMS"/> </span>{userInfo.orgId == "705bf30b-082e-453b-8f51-0ada593e4ca2" && isAccesstoArchive ? <a title={moment('06-30-2024').isAfter(moment(), 'day') ?"Click to edit data for forms in the old EnviroConnect App till June 30, 2024.":'Click to view/print forms in the old EnviroConnect App till June 30, 2024'} href="https://argosems.com/#/home" target="_blank"><span className="argos-tab"><p>ARCHIVED 07/01/2024</p>  </span></a>:""}</span>
    }

    render() {
        return <div className="homepage">
            <ToastContainer /* key={1} *//>
            <div className="theme">
                <NavbarComp/>
                {/* <ReactCSSTransitionGroup
                    transitionAppear={true}
                    transitionAppearTimeout={600}
                    transitionEnterTimeout={600}
                    transitionLeaveTimeout={200}
                    transitionName="SlideIn"
                > 
                    {this.renderTrainingButton()}
                    {this.renderContent()}
                </ReactCSSTransitionGroup> */}
                {this.renderTrainingButton()}
                {this.renderContent()}
            </div> 
        </div>;
    }
}

export function mapStateToProps(state) {
    return { userInfo: getUserLoginInfo() };
}

export const HomePage = withRouter(connect< IHomePageProps, any, any>(mapStateToProps)(HomePageImpl as any));

const setHeaderOpacity: React.CSSProperties = {
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(0,0,0,0.65)',
    position: 'absolute',
    zIndex: 0
};
